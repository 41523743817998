import React from "react";
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import Section4Background from '../../../assets/images/Section4Background.png';
import Phone from '../../../assets/images/phone.png';
import Section6Background from "../../../assets/images/Section6Background.png";

function Index() {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundImage: `url(${Section4Background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                padding: { xs: "20px", md: "50px" }, // Add responsive padding for mobile and desktop
                marginBottom: {xs: '70px', md: '0px'}
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={15} alignItems="center">
                    {/* Left Section: Empty space */}
                    <Grid item xs={12} md={6}>
                        {/*<img src={Phone} width={'80%'}/>*/}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundImage: `url(${Section6Background})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        >
                            <Box
                                component="img"
                                src={Phone}
                                alt="Phone"
                                sx={{
                                    width: { xs: "70%", sm: "70%", md: "80%" },
                                    maxWidth: { xs: "300px", sm: "400px", md: "500px" }, // Responsive sizes
                                    height: "auto",
                                    display: "block",
                                    margin: "0 auto", // Center for mobile
                                    marginTop: {xs: '80px', md: '0px'}
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Right Section: Content */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                maxWidth: "450px",
                                textAlign: { xs: "center", md: "left" },
                                mx: { xs: "auto", md: 0 }, // Center content horizontally on mobile
                                marginTop: {xs: '-90px', sm: '-90px', md: '0px'}
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                sx={{
                                    color: "#F28C33",
                                    textAlign: { xs: "center", md: "left" },
                                }}
                                gutterBottom
                            >
                                EFFICIENCY
                            </Typography>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#000",
                                    textAlign: { xs: "center", md: "left" },
                                }}
                                gutterBottom
                            >
                                Streamline Employee Hours with TimeCloq's Timekeeping
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "#555",
                                    lineHeight: 1.6,
                                    marginBottom: { xs: 2, md: 4 },
                                    textAlign: { xs: "justify", md: "justify" }
                                }}
                            >
                                Manage employee hours efficiently with TimeCloq's timekeeping feature.
                                Our user-friendly interface and advanced tracking capabilities make
                                it easy to monitor and record employee work hours, ensuring accurate
                                payroll calculations and streamlined HR processes.
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "row", sm: "row" },
                                    gap: 2,
                                    justifyContent: { xs: "center", md: "flex-start" },
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        padding: "10px 30px",
                                    }}
                                >
                                    Sign Up
                                </Button>
                                <Button
                                    variant="black"
                                    sx={{
                                        padding: "10px 20px",
                                    }}
                                >
                                    Learn More
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Index;
