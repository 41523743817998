import React from "react";
import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Container,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import './style.css'

const pricingPlans = [
    {
        title: "Basic Plan",
        price: "$19/mo",
        features: [
            "Timekeeping and payroll management",
            "Leave management and tracking",
            "Employee self-service portal",
        ],
        buttonText: "Get Started",
    },
    {
        title: "Business Plan",
        price: "$29/mo",
        features: [
            "All features of the Basic Plan",
            "Detailed reporting and analytics",
            "Customizable workflows and approvals",
            "Integration with e-signature",
        ],
        buttonText: "Go Business",
    },
    {
        title: "Enterprise Plan",
        price: "$49/mo",
        features: [
            "All features of the Business Plan",
            "Dedicated account manager",
            "Priority customer support",
            "Advanced security configurations",
        ],
        buttonText: "Get Started",
    },
];

const comparisonData = [
    { category: "Feature 1", basic: true, business: true, enterprise: true },
    { category: "Feature 2", basic: false, business: true, enterprise: true },
    { category: "Feature 3", basic: false, business: false, enterprise: true },
    { category: "Feature 4", basic: false, business: true, enterprise: true },
];

const plans = [
    { name: "Basic", price: "$180", details: "Per year (save 20%)" },
    { name: "Business", price: "$280", details: "Per year (save 20%)" },
    { name: "Enterprise", price: "$480", details: "Per year (save 20%)" },
];

const features = [
    { category: "Feature Category", features: ["Feature text here", "Feature text here", "Feature text here", "Feature text here", "Feature text here"] },
];

function Index() {
    return (
        <Container maxWidth="lg" sx={{ padding: "40px 20px" }}>
            {/* Pricing Cards Section */}
            <Typography
                variant="subtitle1"
                fontWeight="bold"
                color="primary"
                gutterBottom
                sx={{textAlign: 'center'}}
            >
                SIMPLE
            </Typography>

            <Typography
                variant="h2"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: 2,
                    textAlign: 'center'
                }}
            >
                Pricing Options
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    textAlign: "center",
                    color: "#555",
                    marginBottom: "40px",
                }}
            >
                Choose the plan that best fits your needs and budget.
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "row", sm: "row" },
                    gap: 2,
                    justifyContent: { xs: "center", md: "center" },
                    marginBottom: '40px'
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        padding: "10px 30px",
                    }}
                >
                    Monthly
                </Button>
                <Button
                    variant="black"
                    sx={{
                        padding: "10px 35px",
                    }}
                >
                    Yearly
                </Button>
            </Box>

            <Grid container spacing={4} justifyContent="center">
                {pricingPlans.map((plan, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{marginTop: '40px' }}>
                        <Card
                            sx={{
                                borderRadius: "20px",
                                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                                textAlign: "center",
                                padding: "20px",
                                height: "100%", // Ensure cards have equal height
                                display: "flex", // Make the card a flex container
                                flexDirection: "column", // Stack items vertically
                                justifyContent: "space-between", // Push content to top and button to bottom

                            }}
                        >
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "8px" }}>
                                    {plan.title}
                                </Typography>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: "#FF6B01",
                                        fontWeight: "bold",
                                        marginBottom: "16px",
                                    }}
                                >
                                    {plan.price}
                                </Typography>
                                <Box sx={{ marginBottom: "20px" }}>
                                    {plan.features.map((feature, idx) => (
                                        <Typography
                                            key={idx}
                                            variant="body1"
                                            sx={{ color: "#555", marginBottom: "8px", textAlign: 'left' }}
                                        >
                                            <CheckIcon style={{color: 'FF6B01'}}/> {feature}
                                        </Typography>
                                    ))}
                                </Box>
                            </CardContent>
                            <Button
                                variant="contained"
                                sx={{
                                    padding: "10px 20px",
                                    width: '50%',
                                    margin: 'auto'
                                }}
                            >
                                Get Started
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Pricing Comparison Table */}
            <Typography
                variant="subtitle1"
                fontWeight="bold"
                color="primary"
                gutterBottom
                sx={{textAlign: 'center', marginTop: '90px'}}
            >
                COMPARE
            </Typography>

            <Typography
                variant="h2"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: 2,
                    textAlign: 'center'
                }}
            >
                Pricing Plans
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    textAlign: "center",
                    color: "#555",
                    marginBottom: "20px",
                }}
            >
                Choose the plan that suits your needs.
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "row", sm: "row" },
                    gap: 2,
                    justifyContent: { xs: "center", md: "center" },
                    marginBottom: '50px'
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        padding: "10px 30px",
                    }}
                >
                    Monthly
                </Button>
                <Button
                    variant="black"
                    sx={{
                        padding: "10px 35px",
                    }}
                >
                    Yearly
                </Button>
            </Box>

            <div className="pricing-table">
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        {plans.map((plan, index) => (
                            <th key={index}>
                                <div className="plan-header">

                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        color="primary"
                                        gutterBottom
                                        sx={{textAlign: 'center'}}
                                    >
                                        {plan.name.toUpperCase()}
                                    </Typography>


                                    <Typography
                                        variant="h3"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: 'black !important',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {plan.price}
                                    </Typography>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            textAlign: "center",
                                            color: "#555",
                                        }}
                                    >
                                        {plan.details}
                                    </Typography>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: { xs: "row", sm: "row" },
                                            gap: 2,
                                            justifyContent: { xs: "center", md: "center" },
                                            marginTop: '10px',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                padding: "10px 30px",
                                            }}
                                        >
                                            Get Started
                                        </Button>
                                    </Box>
                                </div>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {features.map((featureCategory, categoryIndex) => (
                        <React.Fragment key={categoryIndex}>
                            <tr className="feature-category-row">
                                <td colSpan={4} className="feature-category-header">
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        color="primary"
                                        gutterBottom
                                        sx={{textAlign: 'left'}}
                                    >
                                        {featureCategory.category}
                                    </Typography>
                                </td>
                            </tr>
                            {featureCategory.features.map((feature, featureIndex) => (
                                <tr key={featureIndex}>
                                    <td style={{textAlign: 'left'}}>{feature}</td>
                                    {plans.map((plan, planIndex) => (
                                        <td key={planIndex} className="feature-cell">
                                            {planIndex === 0 && featureIndex === 0 ? (
                                                <span>10</span>
                                            ) : planIndex === 1 && featureIndex === 0 ? (
                                                <span>25</span>
                                            ) : planIndex === 2 && featureIndex === 0 ? (
                                                <span>Unlimited</span>
                                            ) : (
                                                <span><CheckIcon style={{color: 'FF6B01'}}/></span>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
            </div>
        </Container>
    );
}

export default Index;
