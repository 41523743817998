import React from 'react';
import HomePage from '../pages/home';
import AboutUs from '../pages/aboutus';
import Pricing from '../pages/pricing';
import Services from '../pages/services';
import Signup from '../pages/signup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function Index() {
    return (
        // <p>Router</p>

        <Routes>
            <Route path={"/"} element={<HomePage />}/>
            <Route path={"/about-us"} element={<AboutUs />}/>
            <Route path={"/pricing"} element={<Pricing />} />
            <Route path={"/services"} element={<Services />} />
            <Route path={"/signup"} element={<Signup />} />
        </Routes>


    )
}

export default Index;