import React from "react";
import { Box, Typography, Button, TextField, Grid, Container } from "@mui/material";
import Section8Background from "../../../assets/images/Section8Background.png";
import Ipad from '../../../assets/images/ipad.png';
import Quote from '../../../assets/icons/quote.png';
import FiveStar from '../../../assets/icons/fivestar.png';

function Index() {
    return (
        <Box
            sx={{
                padding: { xs: "40px 20px", md: "80px 40px" },
                textAlign: "center",
                backgroundImage: `url(${Section8Background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <Container maxWidth="md">
                {/* Testimonial Card */}
                <Box
                    sx={{
                        background: "#fff",
                        borderRadius: "16px",
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                        padding: "25px 70px",
                        marginBottom: "40px",
                    }}
                >
                    <Box
                        component="img"
                        src={Quote}
                        alt="Quote"
                        sx={{
                            width: { xs: "20%", sm: "20%", md: "10%" },
                            // maxWidth: { xs: "300px", sm: "400px", md: "500px" }, // Responsive sizes
                            height: "auto",
                            display: "block",
                            margin: "0 auto", // Center for mobile
                        }}
                    />
                    <Typography
                        variant="h4"
                        sx={{
                            color: "#333",
                            marginBottom: "20px",
                            textAlign: 'justify'
                        }}
                    >
                        TimeCloq has completely transformed our HR processes. The timekeeping,
                        payroll, and leave management features are incredibly efficient and
                        user-friendly.
                    </Typography>
                    <Box
                        component="img"
                        src={FiveStar}
                        alt="Five Star"
                        sx={{
                            width: { xs: "50%", sm: "50%", md: "20%" },
                            // maxWidth: { xs: "300px", sm: "400px", md: "500px" }, // Responsive sizes
                            height: "auto",
                            display: "block",
                            margin: "0 auto", // Center for mobile
                        }}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            color: "#555",
                            marginBottom: "10px",
                        }}
                    >
                        <strong>JOHN DOE</strong>
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "#777",
                        }}
                    >
                        HR Manager, XYZ Company
                    </Typography>
                </Box>

                {/* Image */}
                <Box
                    sx={{
                        marginBottom: "40px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={Ipad}
                        alt="Ipad"
                        style={{
                            width: "100%",
                            maxWidth: "600px",
                            borderRadius: "8px",
                        }}
                    />
                </Box>

                {/* Newsletter Subscription */}
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: "bold",
                        color: "#fff",
                        marginBottom: "10px",
                    }}
                >
                    Stay Updated with TimeCloq Newsletter
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: "#fff",
                        marginBottom: "20px",
                    }}
                >
                    Subscribe to our newsletter for the latest HR management insights and updates.
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{ marginBottom: "20px" }}
                >
                    <Grid item xs={12} sm={8} md={6}>
                        <TextField
                            fullWidth
                            placeholder="Your Email Address"
                            variant="outlined"
                            sx={{
                                background: "#fff",
                                borderRadius: "4px",
                            }}
                        />
                    </Grid>

                </Grid>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{ marginBottom: "20px" }}
                >
                    <Grid item xs={12} sm="auto">
                        <Button
                            variant="black"
                            sx={{
                                padding: "10px 30px",
                            }}
                        >
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
                <Typography
                    variant="body1"
                    sx={{
                        color: "#fff",
                    }}
                >
                    By clicking Sign Up, you agree to our Terms and Conditions.
                </Typography>
            </Container>
        </Box>
    );
}

export default Index;
