import React from "react";
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import Tablet from '../../../assets/images/tablet.png';
import Section7Background from '../../../assets/images/Section7Background.png';

function Index() {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url(${Section7Background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: "100%",
                // padding: { xs: "20px", md: "50px" }, // Add responsive padding for mobile and desktop
            }}
        >
            <Container maxWidth="lg">
                <Grid container  alignItems="center" sx={{ marginBottom: '50px'}}>
                    {/* Left Section: Empty space */}
                    <Grid item xs={12} md={6} >
                        <Box
                            sx={{
                                maxWidth: "400px",
                                textAlign: { xs: "center", md: "left" },
                                mx: { xs: "auto", md: 0 }, // Center content horizontally on mobile

                            }}
                            paddingLeft={{xs: 0, md: 15}}
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                sx={{
                                    color: "#F28C33",
                                    textAlign: { xs: "center", md: "left" },
                                }}
                                gutterBottom
                            >
                                SIMPLIFY
                            </Typography>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#000",
                                    textAlign: { xs: "center", md: "left" },
                                }}
                                gutterBottom
                            >
                                Boost Productivity and Employee Satisfaction with TimeCloq
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "#555",
                                    lineHeight: 1.6,
                                    marginBottom: { xs: 2, md: 4 },
                                    textAlign: { xs: "justify", md: "justify" },
                                }}
                            >
                                Discover the benefits of using TimeCloq and unlock
                                increased productivity, compliance and employee
                                satisfaction. Our saas HRIS platform streamlines
                                timekeeping, payroll, and leave management, saving
                                you time and ensuring accuracy. With TimeCloq, you
                                can focus on what matters most-growing your business
                                and taking care of your employees.
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "row", sm: "row" },
                                    gap: 2,
                                    justifyContent: { xs: "center", md: "flex-start" },
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        padding: "10px 30px",
                                    }}
                                >
                                    Sign Up
                                </Button>
                                <Button
                                    variant="black"
                                    sx={{
                                        padding: "10px 20px",
                                    }}
                                >
                                    Learn More
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Right Section: Content */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "center", md: "center" }, // Center on mobile, align right on larger screens
                                alignItems: "center",

                            }}
                        >
                            <img
                                src={Tablet}
                                alt="Computer"
                                style={{
                                    width: "75%", // Make the image responsive
                                    maxWidth: "600px", // Adjust the max width for larger screens
                                    height: "auto", // Maintain aspect ratio
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    );
}

export default Index;
