import React from "react";
import { Box, Container, Typography, Button, Grid, Card, CardContent } from '@mui/material';
import AboutUsBackground4 from '../../../assets/images/AboutUsBackground4.png';
import { orange } from '@mui/material/colors';

function Index() {
    return(

        <Box sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundImage: `url(${AboutUsBackground4})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            py: { xs: 6, md: 8 }, // Adjust vertical padding for mobile and desktop
            px: { xs: 2, md: 4 }, // Adjust horizontal padding for mobile and desktop
            textAlign: 'center',
        }}>
            <Box>
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                    }}
                    color={"white"}
                >
                    Simple and Affordable HR Solutions
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: {xs: "justify", md: "center"},
                        color: "white",
                    }}
                >
                    Streamline your HR processes with TimeCloq's timekeeping, payroll, and leave management features.
                </Typography>
            </Box>
            {/* Button Section */}
            <Box
                sx={{
                    mt: { xs: 4, md: 6 }, // Adjust margin-top for mobile and desktop
                    display: 'flex',
                    flexDirection: { xs: 'row', sm: 'row' }, // Stack buttons on mobile, inline on larger screens
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2, // Add spacing between buttons
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                        mr: 2,
                        padding: '10px 45px'
                    }}
                >
                    Sign Up
                </Button>
                <Button
                    variant="black"
                    color="secondary"
                    sx={{
                        padding: '10px 35px',
                    }}
                >
                    Learn More
                </Button>
            </Box>
        </Box>
    )
}

export default Index;