import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import './style.css';

const NavButton = styled(Button)(({ theme }) => ({
    color: '#000',
    marginRight: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 500,
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'inline-flex',
    },
}));

function Index() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsScrolled(true); // Change color when scrolled past 50px
        } else {
            setIsScrolled(false); // Default color
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
        };
    }, []);

    const drawer = (
        <Box  onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/*<Typography variant="h2" sx={{ my: 2 }}>*/}
            {/*    izzyhr.com*/}
            {/*</Typography>*/}
            <List>
                <ListItem >
                    <Link to="/">
                        <ListItemText className={"menu-list"} primary="HR Solutions" />
                    </Link>
                </ListItem>
                <ListItem >
                    <Link to="/pricing">
                    <   ListItemText className={"menu-list"} primary="Pricing" />
                    </Link>
                </ListItem>

                <ListItem >
                    <Link to="/about-us">
                        <ListItemText className={"menu-list"} primary="About Us" />
                    </Link>
                </ListItem>
            </List>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, px: 2 }}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#F28C33',
                        textTransform: 'none',
                        borderRadius: '25px',
                        padding: '10px 20px',
                        color: '#fff',
                    }}
                >
                    Ready to get started ?
                </Button>
                <Link to="/signup">
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#393939',
                            color: '#fff',
                            textTransform: 'none',
                            borderRadius: '25px',
                            padding: '10px 20px',
                        }}
                    >
                        Sign up for free
                    </Button>
                </Link>
            </Box>
        </Box>
    );

    return (
        <AppBar
            position="fixed"
            color="transparent"
            elevation={0}
            sx={{
                padding: { xs: '10px 20px', md: '10px 150px' },
                background: isScrolled ? 'white' : 'transparent',
                transition: 'background-color 0.3s ease',
                boxShadow: isScrolled ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : ''
            }}
            className={"navbar"}
        >
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            mr: { xs: 2, md: 5 },
                            flexGrow: { xs: 1, md: 0 },
                            fontWeight: 'bold'
                        }}
                    >
                        TimeCloq
                    </Typography>
                    <Link to="/">
                        <NavButton className={"menu-list"} style={{fontSize: '20px'}}>HR Solutions</NavButton>
                    </Link>
                    <Link to="/pricing">
                        <NavButton className={"menu-list"}>Pricing</NavButton>
                    </Link>
                    <Link to="/about-us">
                        <NavButton className={"menu-list"}>About Us</NavButton>
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        alignItems: 'center',
                        marginLeft: 'auto',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            mr: 2,
                            textTransform: 'none',
                            padding: '10px 20px',
                        }}
                    >
                        Ready to get started ?
                    </Button>
                    <Link to="/signup">
                        <Button
                            variant="black"
                            sx={{
                                backgroundColor: '#393939',
                                textTransform: 'none',
                                padding: '10px 20px',
                            }}
                        >
                            Sign up for free
                        </Button>
                    </Link>
                </Box>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { xs: 'block', md: 'none' }, marginLeft: 'auto' }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
            <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Improves mobile open performance
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                {drawer}
            </Drawer>
        </AppBar>
    );
}

export default Index;
