import React from 'react';
import {BrowserRouter} from "react-router-dom";
import Box from '@mui/material/Box'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AppRoutes from "../Router"
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Section1Background from '../assets/images/Section1Background.png';


const theme = createTheme({
    palette: {
        primary: {
            main: '#FF6B01',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'GT Walsheim Pro Regular, sans-serif',
        h1: {
            fontSize: '73px',
            fontWeight: 700,
            '@media (max-width:1200px)': {
                fontSize: '60px',
            },
            '@media (max-width:900px)': {
                fontSize: '48px',
            },
            '@media (max-width:600px)': {
                fontSize: '35px',
            },
        },
        h2: {
            fontSize: '45px',
            '@media (max-width:1200px)': {
                fontSize: '40px',
            },
            '@media (max-width:900px)': {
                fontSize: '35px',
            },
            '@media (max-width:600px)': {
                fontSize: '30px',
            },
        },
        h3: {
            fontSize: "27px"
        },
        h4: {
            fontSize: "30px",
            '@media (max-width:1200px)': {
                fontSize: '28px',
            },
            '@media (max-width:900px)': {
                fontSize: '25px',
            },
            '@media (max-width:600px)': {
                fontSize: '22px',
            },
        },
        subtitle1: {
            fontSize: "22px",
        },
        body1: {
            fontSize: "20px",
            lineHeight: "28px",
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Applies to all buttons
                    borderRadius: '25px',
                    fontSize: '20px'
                },
            },
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        backgroundColor: '#F28C33',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#d77428',
                        },
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: 'transparent',
                        color: '#fff',
                        border: '2px solid white',
                    },
                },
                {
                    props: { variant: 'black' },
                    style: {
                        backgroundColor: '#393939', // Black background
                        color: '#fff',           // White text
                        '&:hover': {
                            backgroundColor: '#333',
                        },
                    },
                },
            ],
        },
    },
});

function Index () {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Box>
                    {/*<Box*/}
                    {/*    component='main'*/}
                    {/*    id='main'*/}
                    {/*    sx={{*/}
                    {/*        backgroundImage: `url(${Section1Background})`,*/}
                    {/*        minHeight: '110vh',*/}
                    {/*        backgroundSize: 'cover',*/}
                    {/*        backgroundPosition: 'center',*/}
                    {/*        backgroundRepeat: 'no-repeat',*/}
                    {/*    }}*/}
                    {/*    display='flex'*/}
                    {/*    flexDirection='column'*/}
                    {/*    justifyContent='space-between'*/}
                    {/*>*/}
                    {/*    <NavBar/>*/}
                        <AppRoutes />

                    {/*</Box>*/}
                    <Footer />
                </Box>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default Index;