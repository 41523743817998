import React, { useState } from 'react';
import AboutUsBackground1 from "../../assets/images/AboutUsBackground1.png";
import {
    Typography,
    Box,
    TextField,
    Button,
    FormControlLabel,
    RadioGroup,
    Radio
} from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import './style.css'; // Create this file for custom animations

export default function Index() {
    const [step, setStep] = useState(1);
    const [direction, setDirection] = useState<'left' | 'right'>('right'); // Tracks animation direction
    const [formData, setFormData] = useState({
        fullName: '',
        companyName: '',
        email: '',
        industry: '',
        employeeRange: '',
        excelFile: null
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e: any) => {
        setFormData({ ...formData, excelFile: e.target.files[0] });
    };

    const handleNext = (e: any) => {
        e.preventDefault();
        if (step < 4) {
            setDirection('right');
            setStep(step + 1);
        } else {
            console.log('Final step data:', formData);
        }
    };

    const handlePrevious = (e: any) => {
        e.preventDefault();
        if (step > 1) {
            setDirection('left');
            setStep(step - 1);
        }
    };

    const renderFormFields = () => {
        if (step === 1) {
            return (
                <>
                    <TextField
                        label="Full Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        fullWidth
                        required
                        variant="outlined"
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        required
                        variant="outlined"
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="Company Name"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        fullWidth
                        required
                        variant="outlined"
                        sx={{ mb: 2 }}
                    />
                </>
            );
        } else if (step === 2) {
            return (
                <>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        What type of industry does your company belong to? *
                    </Typography>
                    <RadioGroup
                        name="industry"
                        value={formData.industry}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="Technology" control={<Radio />} label="Technology" />
                        <FormControlLabel value="Finance" control={<Radio />} label="Finance" />
                        <FormControlLabel value="Healthcare" control={<Radio />} label="Healthcare" />
                        <FormControlLabel value="Education" control={<Radio />} label="Education" />
                        <FormControlLabel value="Retail" control={<Radio />} label="Retail" />
                        <FormControlLabel value="Manufacturing" control={<Radio />} label="Manufacturing" />
                        <FormControlLabel value="Other" control={<Radio />} label="Other" />
                    </RadioGroup>
                </>
            );
        }else if (step === 3) {
            return (
                <>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        How many employees does your company has? (Select range only.) *
                    </Typography>
                    <RadioGroup
                        name="employeeRange"
                        value={formData.employeeRange}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="less_than_10_employees" control={<Radio />} label="Less than 10 employees" />
                        <FormControlLabel value="10_to_20_employees" control={<Radio />} label="10 to 20 employees"/>
                        <FormControlLabel value="20_to_50_employees" control={<Radio />} label="20 to 50 employees" />
                        <FormControlLabel value="50_to_100_employees" control={<Radio />} label="50 to 100 employees" />
                        <FormControlLabel value="more_than_100_employees" control={<Radio />} label="More than 100 employees" />
                    </RadioGroup>
                </>
            );
        }else if(step === 4) {
            return (
                <>
                    <Typography variant="body1" sx={{ mt: 4, mb: 2 }}>
                        Optional: Upload your company's employees in an Excel file
                    </Typography>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ mb: 2, backgroundColor: '#F28C33', borderRadius: '25px', color: '#fff' }}
                    >
                        Upload File
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>
                    {formData.excelFile && (
                        <Typography variant="body2" >
                            Uploaded File: {formData.excelFile}
                        </Typography>
                    )}
                </>
            )
        }

    };

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: '85vh' }}>
            {/* Left Section */}
            <Box
                sx={{
                    flex: 1,
                    backgroundImage: `url(${AboutUsBackground1})`,
                    backgroundSize: 'cover',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2,
                    textAlign: 'center'
                }}
            >
                <Typography variant="h1" sx={{ my: 2 }}>
                    <strong>TimeCloq</strong>
                </Typography>
                <Typography variant="body1" sx={{ maxWidth: '300px', mx: 'auto' }}>
                    Simplify your HR processes. Join us today and take the first step towards efficiency and growth.
                </Typography>
            </Box>

            {/* Right Section */}
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: 2
                }}
            >
                <Typography variant="h2" sx={{ mt: 7, mb: 5 }}>
                    <strong>Start your free trial now</strong>
                </Typography>

                    <form
                        onSubmit={handleNext}
                        style={{
                            width: '100%',
                            maxWidth: '400px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <CSSTransition
                            key={step} // Use the step number to dynamically trigger animation
                            in={true} // Always true to use key as animation trigger
                            timeout={300}
                            classNames={direction === 'right' ? 'slide-right' : 'slide-left'}
                            unmountOnExit
                        >
                            <Box>{renderFormFields()}</Box>
                        </CSSTransition>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            {
                                step > 1 ?
                                    <Button
                                        variant="black"
                                        sx={{
                                            padding: '10px 40px',
                                        }}
                                        onClick={handlePrevious}
                                    >
                                        Previous
                                    </Button> : <p> </p>
                            }

                            <Button
                                variant="contained"
                                sx={{
                                    padding: '10px 55px'
                                }}
                                type={'submit'}
                                // onClick={handleNext}
                            >
                                {step < 4 ? 'Next' : 'Submit'}
                            </Button>
                        </Box>
                    </form>


            </Box>
        </Box>
    );
}
