import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';

function Index() {
    return (
        <>
            <Container
                sx={{
                    position: 'relative', // Allows for flexible positioning of child elements
                    display: 'flex', // Enables flexbox alignment
                    flexDirection: 'column', // Stack content vertically on small screens
                    alignItems: 'center', // Center content horizontally
                    height: '40vh',
                    marginTop: {xs: '50px', md: '50px'}
                }}
                maxWidth="lg"
            >
                <Box
                    className="hero-section"
                    textAlign="center"
                    sx={{
                        backgroundColor: 'transparent', // No background to maintain original design
                        width: '100%', // Ensures the box scales to fit the screen width
                        marginTop: {xs: '80px', sm: '80px', md: '100px'}
                    }}
                >
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                            fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.3rem' }, // Adjust font size for responsiveness
                            fontWeight: 'bold',
                        }}
                    >
                        Services
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        paragraph
                        sx={{
                            mt: 2,
                            mb: 2,
                            fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, // Responsive font size
                        }}
                    >
                        Choose the plan that suits your business needs and budget.
                    </Typography>
                </Box>
            </Container>
        </>
    );
}

export default Index;
